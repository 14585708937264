import decode from 'jwt-decode'
import { emptyFilter } from '../constants'
import { auth } from '../api/auth'
import { isBefore } from 'date-fns'
/**
 * @param func
 * @param wait
 * @returns {function(...[*]): void}
 */
export const debounce = (func, wait = 500) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

/**
 * @param {number} number
 * @param {array} [words = ["яблоко", "яблока", "яблок"]]
 * @return {string}
 */
export const numberDeclension = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]

/**
 * It updates the state with the new value and returns the updated state
 * @param {Object} state
 * @param {Object} value
 * @return {Object}
 */
export function simpleReducer(state, value) {
  return {
    ...state,
    ...value
  }
}

/**
 * Для глубокого обновления передавайте данные в формате { key: String, value: Object }
 * @param {Object} state
 * @param {Object} data
 * @return {Object}
 */
export function deepReducer(state, data) {
  if (data?.key) {
    if (!(typeof data?.value === 'object') || !state?.[data.key]) {
      console.error(
        `значение data.value=${data.value} должно быть обьектом и ключ data.key=${data.key} должен существовать`
      )
      return state
    }
    return {
      ...state,
      [data.key]: {
        ...state[data.key],
        ...data.value
      }
    }
  }
  return {
    ...state,
    ...data
  }
}

/**
 * Standard filter for select
 */
export function selectSearchFilter(input, option) {
  let children = ''
  if (typeof option?.children === 'string') children = option?.children
  else children = option?.children?.[0]?.props?.children

  return children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
}

export const getPropValue = (object = {}, path = '') =>
  path.split('.').reduce((o, x) => (o === undefined ? o : o[x]), object)

/**
 * Group by prop
 * @param list []
 * @param prop string
 * @returns {*}
 */
/* eslint-disable indent */
export function groupBy(list, prop, defaultGroupName = 'Без названия') {
  return Array.isArray(list)
    ? list.reduce((groups, item) => {
        const groupName = getPropValue(item, prop) || defaultGroupName
        const group = groups[groupName] || []
        group.push(item)
        groups[groupName] = group
        return groups
      }, {})
    : {}
}
/* eslint-enable indent */

export function upperFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

/**
 * @param list1 []
 * @param list2 []
 * @returns {boolean}
 */
export function areArraysIntersect(list1 = [], list2 = []) {
  return list1.some(c => list2.includes(c))
}

/**
 * получает значение обьекта по сложному ключу
 * @param {String} key 'a.b.0.c'
 * @param {Object} obj { a: { b: [{ c: 'value' }] } }
 * @return {String} 'value'
 */
export function getValueByDotNotationKey(key = '', obj = {}) {
  return key.split('.').reduce((o, i) => o?.[i], obj)
}
export function sortBy(key, emptyLast = false) {
  return (a, b) => {
    const aValue = getValueByDotNotationKey(key, a)
    const bValue = getValueByDotNotationKey(key, b)
    if (emptyLast && !aValue) {
      return 1
    }
    if (emptyLast && !bValue) {
      return -1
    }
    return aValue > bValue ? 1 : bValue > aValue ? -1 : 0
  }
}
export const sortByLastHistory =
  (field = 'expireAt') =>
  (a, b) => {
    return isBefore(
      new Date(a.history?.[a.history.length - 1]?.[field] || new Date()),
      new Date(b.history?.[b.history.length - 1]?.[field] || new Date())
    )
      ? -1
      : isBefore(
          new Date(b.history?.[b.history.length - 1]?.[field] || new Date()),
          new Date(a.history?.[a.history.length - 1]?.[field] || new Date())
        )
      ? 1
      : 0
  }

/**
 * key - поле в оригинальном массиве
 * target - массив эталон. в этом порядке должны быть отсортированы элементы оригинального массива по заданному полю
 */
export function sortByArray(key, target) {
  return (a, b) => {
    // если в эталоне нет искомого элемента (-1), то такой элемент будет в конце
    const indexA = target.indexOf(a[key])
    const indexB = target.indexOf(b[key])
    if (indexA >= 0 && indexB >= 0) {
      // все элементы есть в эталоне
      if (indexA < indexB) return -1 // А наверх
      if (indexB < indexA) return 1 // Б наверх
      return 0 // не двигать
    } else if (indexA < 0 && indexB >= 0) {
      // А нет в эталоне, значит элемент будет в конце
      return 1 // Б наверх
    } else if (indexA >= 0 && indexB < 0) {
      // Б нет в эталоне, значит элемент будет в конце
      return -1 // А наверх
    }
    return 0 // не двигать
  }
}

export function convertArrayToObject(array = [], key) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [getValueByDotNotationKey(key, item)]: item
    }
  }, initialValue)
}

export function convertArrayToObjectWithIndex(array = []) {
  return array.reduce((obj, item, index) => {
    return {
      ...obj,
      [index]: item
    }
  }, {})
}

export function convertObjectToArray(object = {}) {
  return Object.values(object)
}

export function arrayFirst(list) {
  // Native (works even with potentially undefined/null, like _.first)
  return [].concat(list).shift()
}

export function arrayLast(list) {
  // Native (works even with potentially undefined/null, like _.first)
  return [].concat(list).pop()
}

export function arrayUniqValues(list = []) {
  return list.filter((item, pos) => list.indexOf(item) === pos)
}

export function concatUtmValuesByKey(list = [], key, parentUtmMedium = null) {
  const result = []
  if (!key) return result
  const pushValue = e => {
    if (e && e.forEach) {
      // check for array
      result.push(...(e?.map(item => item?.name) || []))
    } else if (e) {
      result.push(e?.name)
    }
  }
  list.forEach(e => {
    if (parentUtmMedium) {
      pushValue(e.utmMediums?.find(item => item.name === parentUtmMedium)?.[key])
    } else {
      pushValue(e[key])
    }
  })
  return arrayUniqValues(result)
}

export function removeSpecSymbols(value) {
  return value.replace(/[.*+?^${}()|_[\]\\]/g, '')
}

export const strongPasswordMessage =
  'Пароль должен быть не меньше 6 символов. Используйте латинские буквы, заглавные буквы, цифры и специальные символы'
export const testPassword = pwString => {
  let strength = 0
  let message = ''

  strength += /[A-Z]+/.test(pwString) ? 1 : 0
  strength += /[a-z]+/.test(pwString) ? 1 : 0
  strength += /[0-9]+/.test(pwString) ? 1 : 0
  strength += /[\W]+/.test(pwString) ? 1 : 0
  strength += pwString.length > 5 ? 1 : 0

  switch (strength) {
    case 4:
      message = 'Средний пароль'
      break
    case 5:
      message = 'Сильный пароль'
      break
    default:
      message = 'Слабый пароль'
      break
  }
  return {
    isStrong: strength > 4,
    message
  }
}

export const validatePass = form => async (rule, value) => {
  const ERROR_REUIRED = 'Введите пароль'
  const ERROR_EQUAL = 'Пароли должны совпадать'
  const { password, repeatPassword } = form.getFieldsValue()
  const strongPassword = testPassword(value)

  if (rule.field === 'password' && !value) {
    throw new Error(ERROR_REUIRED)
  } else if (rule.field === 'password' && !strongPassword.isStrong) {
    throw new Error(strongPassword.message)
  } else if (rule.field === 'repeatPassword' && !value) {
    throw new Error(ERROR_REUIRED)
  } else if (password && repeatPassword && password !== repeatPassword) {
    throw new Error(ERROR_EQUAL)
  }
}
/**
 *
 * @param {object} object
 * @param {array|string} path
 * @returns {*}
 */
export const getObjectValueByPath = (object = {}, path) => {
  if (Array.isArray(path)) {
    return path.reduce(
      (current, key) => (current && current[key] !== undefined ? current[key] : undefined),
      object
    )
  }
  if (typeof path === 'string') {
    return object[path]
  }
  return undefined
}

export const isString = str => str && typeof str.valueOf() === 'string'

// '1 2  3   4'.split(' ') = ['1', '', '2', '', '', '3'...] filter only words
export const parseToArrayWords = string =>
  isString(string) ? string.split(' ').filter(Boolean) : []

export function isLocal() {
  return window.location.origin.includes('localhost')
}

export const acronym = (string, wordsCount = 2) =>
  string
    .split(' ')
    .splice(0, wordsCount)
    .map(word => word[0]?.toUpperCase())
    .join('')

export function getEnvironmentByHost() {
  switch (window.location.host) {
    case 'cloud.solvopro.improvity.ru':
      return 'cloud'
    case 'demo.solvopro.improvity.ru':
      return 'demo'
    case 'localhost:3000':
    case 'localhost:3001':
      return 'localhost'
    default:
      return window.location.host
  }
}

/**
 * Генерирует рандомный цвет для определенной строки
 * @returns string
 * @param string
 * @param defaultColor
 */
export const generateHexFromString = (string, defaultColor = '#666f73') => {
  let hash = 0
  if (!string) {
    return defaultColor
  }
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

export const stringHasOnlyDigits = string => {
  return isString(string) ? !string.match(/\D/) : false
}

export const makeSearchParamByPhoneOrName = value => {
  if (value) {
    const search = clearSearchQuery(value)
    const hypothesisPhone = search?.replaceAll(' ', '')
    return stringHasOnlyDigits(hypothesisPhone) ? { phone: hypothesisPhone } : { name: search }
  }
  return {}
}

export const getListOfNamesSeparatedByCommas = (list, fieldName = 'name') =>
  list
    ?.map(item => item?.[fieldName])
    ?.filter(Boolean)
    ?.join(', ')

export const clearSearchQuery = value => {
  // Убираем все спецсимволы, лишние пробелы в начале и конце, "двойные и более" пробелы внутри строки
  return parseToArrayWords(removeSpecSymbols(value)?.trim()).join(' ')
}

export const makeFilterParams = filters => {
  const data = {}
  const filterKeys = Object.entries(filters || {}).filter(([, v]) => v)
  if (filterKeys.length) {
    filterKeys.forEach(([key, value]) => {
      switch (true) {
        case key === 'phone':
          data[key] = value[0]?.replace(/\D/g, '')
          break
        case key === 'name':
        case key === 'createdAt':
          data[key] = value[0]
          break
        default:
          if (typeof value !== 'boolean' && value?.includes('null')) {
            data[key] = null // for filter by empty values in field
          } else {
            data[key] = value
          }
      }
    })
  }
  return data
}

/** candidate.js::fetchGetCandidates()
 * /api/v1/candidates/filterValues/requestType - пример для "Тип обращения"
 * [
 *   0: {_id: "Партнёры/Колл-центры", count: 1}
 *   1: {_id: null, count: 11}
 *   2: {_id: "АК/Партнёры", count: 5}
 *   3: {_id: "Отклик", count: 7}
 *   4: {_id: "Акция "Приведи друга"", count: 6}
 * ]
 * Сразу форматируем массив значений фильтра в необходимый для <Table> формат.
 * Запрос по значениям фильтра возвращает все возможные значения по заданному полю, даже пустые значения этого поля.
 * По этому показываем значение фильтра emptyFilter вначале только если оно пришло из бэка.
 */
export const formatFilterValues = values => {
  const formatted = []
  values?.forEach(item => {
    if (item._id === null) {
      formatted.unshift(emptyFilter)
    } else {
      formatted.push({ text: item._id, value: item._id })
    }
  })
  return formatted
}

export const isNoAuthToken = () =>
  auth.getToken() && decode(auth.getToken())?.user_roles?.includes('workflowNoAuthUser')

export const getTableScroll = (height = 350) => ({
  y: `calc(100vh - ${height}px)` /* height = высота_хедера_таблицы + высота_фильтров + высота_шапки */
})
export const getVirtualTableScroll = (height = 400) => ({
  y: height,
  x: '100vw'
})

export const initialPagination = { current: 1, pageSize: 100 }

export const getFilterDrawerCount = options => {
  const list = { ...(options || {}) }
  delete list.states // бизнес логика говорит что статусы это не фильтр
  /**
   * start, end, period - параметры одного фильтра, значит оставим только "period" для подсчета
   */
  delete list.start
  delete list.end
  return Object.values(list)?.filter(
    e => !(e === undefined || (typeof e === 'number' && isNaN(e)) || !e)
  )?.length
}

export const arrayEquals = (a, b) => {
  if (!(Array.isArray(a) && Array.isArray(b) && a.length === b.length)) {
    return false
  }
  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return sortedA.every((val, index) => val === sortedB[index])
}
export const filterObjectNoEmptyKeys = obj =>
  Object.fromEntries(Object.entries(obj || {}).filter(([, value]) => !!value))

export const getFieldsForReuse = data => {
  const updatableFields = [
    'age',
    'birthday',
    'citizenship',
    'currentOrganization',
    'currentPosition',
    'education',
    'email',
    'experience',
    'preferredPosition',
    'preferredRate',
    'profileUrl',
    'requestType',
    'source',
    'utm_source'
  ]
  return {
    ...updatableFields.reduce((prev, cur) => {
      const item = data[cur]
      if (Array.isArray(item) ? item.length : item) prev[cur] = item
      return prev
    }, {})
  }
}
