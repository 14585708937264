import React, { useEffect, useReducer } from 'react'
import { Row, Col, Select, Button, Typography, Input } from 'antd'
import { add } from 'date-fns'
import { NumericFormat } from 'react-number-format'

import { simpleReducer } from '../../helpers'

const { Option } = Select
const { Title, Text } = Typography

function SlaTime({ onChange, title, withoutButton = false, data, disabled = false }) {
  const [state, setState] = useReducer(simpleReducer, {
    days: 0,
    hours: 0,
    minutes: 0
  })

  useEffect(() => {
    if (data) {
      setState({
        days: data.days,
        hours: data.hours || 0,
        minutes: data.minutes || 0
      })
    }
  }, [data])

  const handleUpdate = () => {
    const nextCall = add(new Date(), {
      days: state.days,
      hours: state.hours,
      minutes: state.minutes
    })
    onChange(nextCall)
  }

  const handleChange = (key, value) => {
    setState({ [key]: value })
    onChange({ ...state, [key]: value })
  }

  const getNumList = (length, step = 1) => {
    return Array.from({ length }, (_, i) => i * step)
  }

  const isValid = () => state.days !== '0' || state.hours !== '0' || state.minutes !== '0'

  // const days = getNumList(6)
  const hours = getNumList(24)
  const minutes = getNumList(12, 5)

  return (
    <>
      {title && (
        <Title level={4} className="title">
          {title}
        </Title>
      )}
      <Row className="mb-3" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={8}>
          <Text className="title" disabled={disabled}>
            ДНЕЙ
          </Text>
          <NumericFormat
            className="d-flex"
            value={state.days}
            isAllowed={({ value }) => value?.length === value?.replace(/\D/g, '')?.length}
            onChange={e => handleChange('days', e?.target?.value)}
            customInput={Input}
            disabled={disabled}
          />
          {/*<Select*/}
          {/*  className="d-flex"*/}
          {/*  value={state.days}*/}
          {/*  onChange={e => handleChange('days', e?.target?.value)}*/}
          {/*>*/}
          {/*  {days.map(v => (*/}
          {/*    <Option key={v} value={v}>*/}
          {/*      {v}*/}
          {/*    </Option>*/}
          {/*  ))}*/}
          {/*</Select>*/}
        </Col>
        <Col className="gutter-row" span={8}>
          <Text className="title" disabled={disabled}>
            ЧАСОВ
          </Text>
          <Select
            className="d-flex"
            value={state.hours}
            onChange={e => handleChange('hours', e)}
            disabled={disabled}
          >
            {hours.map(v => (
              <Option key={v} value={v}>
                {v}
              </Option>
            ))}
          </Select>
        </Col>
        <Col className="gutter-row" span={8}>
          <Text className="title" disabled={disabled}>
            МИНУТ
          </Text>
          <Select
            className="d-flex"
            value={state.minutes}
            onChange={e => handleChange('minutes', e)}
            disabled={disabled}
          >
            {minutes.map(v => (
              <Option key={v} value={v}>
                {v}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      {!withoutButton && (
        <div className="btn-wrap">
          <Button className="green" onClick={handleUpdate} disabled={!isValid()}>
            Сохранить
          </Button>
          <Button className="red" onClick={() => onChange(null)}>
            Отмена
          </Button>
        </div>
      )}
    </>
  )
}
export default SlaTime
