import React, { useCallback } from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { PatternFormat } from 'react-number-format'
import { colSettings } from '../../../constants'
import { PHONE_VALIDATION_MESSAGE, validatePhone } from '../../../helpers/phone'

const PhoneItem = ({
  className = '',
  label = 'Телефон',
  name = 'phone',
  labelCol = colSettings.full,
  wrapperCol = colSettings.full,
  labelAlign = 'right',
  required = false,
  disabled = false,
  autoComplete = 'off',
  size = 'middle',
  onChange
}) => {
  const handleChange = useCallback(event => onChange?.(event), [onChange])

  return (
    <Form.Item
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      name={name}
      rules={[
        {
          required,
          message: PHONE_VALIDATION_MESSAGE,
          validator: required ? validatePhone : undefined
        }
      ]}
    >
      <PatternFormat
        size={size}
        format="+# (###) ### ## ##"
        allowEmptyFormatting
        mask="_"
        className={`ant-input ${className}`}
        autoComplete={autoComplete}
        onChange={handleChange}
        disabled={disabled}
        customInput={Input}
      />
    </Form.Item>
  )
}

PhoneItem.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  labelCol: PropTypes.shape({ span: PropTypes.number }),
  wrapperCol: PropTypes.shape({ span: PropTypes.number }),
  labelAlign: PropTypes.oneOf(['left', 'right']),
  required: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string
}

export default PhoneItem
