import { Typography, Button, message, Modal, Row, Col } from 'antd'
import React, { useContext, useEffect, useMemo, useState } from 'react'
const { Text } = Typography
import { OrgStructureContext } from '../../../../../contexts/orgStructureContext'
import { useMutateDeleteOrgUnit } from '../../../../../api/orgStructure'
import { FolderFilled } from '@ant-design/icons'
import orgDeleteUnitImg from '../../../../../img/orgStructure/remove.svg'
import { noOrgStructureName, penultimateIndex } from '../../../../../constants/orgStructure'
import { useDepartmentsQuery } from '../../../../../api/department'
import { useMutateUser, useUsers } from '../../../../../api/users'
import { appConfig } from '../../../../../constants/appConfig'
import { useQueryClient } from 'react-query'

function DeleteUnit({ selectedUnits }) {
  const queryClient = useQueryClient()
  const orgStructure = useContext(OrgStructureContext)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [departments, setDepartments] = useState([])

  const deletedUnit = useMemo(
    () =>
      (
        selectedUnits?.[selectedUnits?.length - penultimateIndex]?.children || orgStructure.units
      )?.find(unit => unit.deleted),
    [orgStructure, selectedUnits]
  )

  const { data: dataManagers, isError: isErrorManagers } = useUsers(
    { filters: { roles: [appConfig.roles.manager] } },
    { enabled: !!selectedUnits }
  )
  useEffect(() => {
    if (isErrorManagers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [isErrorManagers])

  const {
    mutate: mutateUser,
    isSuccess: isSuccessUpdateUser,
    isError: isErrorUpdateUser,
    isLoading: isLoadingUpdateUser
  } = useMutateUser(queryClient)

  useEffect(() => {
    if (isSuccessUpdateUser) {
      message.success('Пользователь успешно обновлен')
    } else if (isErrorUpdateUser) {
      message.error('Ошибка сохранения данных пользователя')
    }
  }, [isSuccessUpdateUser, isErrorUpdateUser])

  const {
    data: departmentsData,
    isSuccess: isSuccessDepartments,
    isError: isErrorDepartments
  } = useDepartmentsQuery(
    { parentOrganizationUnits: [selectedUnits?.[selectedUnits?.length - 1]?._id] },
    { enabled: isModalVisible && !!selectedUnits?.[selectedUnits?.length - 1]?._id }
  )

  useEffect(() => {
    if (isSuccessDepartments && departmentsData?.data) {
      setDepartments(departmentsData?.data)
    }
    if (isErrorDepartments) message.error('Ошибка загрузки орг. единиц')
  }, [departmentsData, isSuccessDepartments, isErrorDepartments])

  const { data: deletedUnitDepartmentsData, isError: isErrorDeletedUnitDepartmentsData } =
    useDepartmentsQuery(
      { parentOrganizationUnits: [deletedUnit?._id] },
      { enabled: isModalVisible && !!deletedUnit?._id }
    )

  useEffect(() => {
    if (isErrorDeletedUnitDepartmentsData) message.error('Ошибка загрузки орг. единиц')
  }, [isErrorDeletedUnitDepartmentsData])

  const {
    isSuccess: isSuccessOrgStructure,
    isError: isErrorOrgStructure,
    mutate: mutateOrgStructure,
    isLoading: isLoadingOrgStructure
  } = useMutateDeleteOrgUnit()

  useEffect(() => {
    if (isSuccessOrgStructure) {
      message.success('Данные успешно сохранены')
      setIsModalVisible(false)
    } else if (isErrorOrgStructure) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessOrgStructure, isErrorOrgStructure])

  const handleClose = () => setIsModalVisible(false)

  const hasChildren = useMemo(
    () => !!selectedUnits?.[selectedUnits?.length - 1]?.children?.length,
    [selectedUnits]
  )

  const joinDepartmentsIntoUserScope = async (unit, departments) =>
    unit?.users.forEach(async userId => {
      const user = (dataManagers?.data || []).find(u => u._id === userId)
      await mutateUser({
        id: user._id,
        data: {
          ...user,
          scope: {
            ...user.scope,
            departmentReport: [
              ...new Set([...user.scope.departmentReport, ...(departments?.map(e => e._id) || [])])
            ]
          }
        }
      })
    })

  const removeSelectedUnitDepartmentsFromUserScope = async () =>
    selectedUnits?.[selectedUnits.length - 1]?.users.forEach(async userId => {
      const user = (dataManagers?.data || []).find(u => u._id === userId)
      await mutateUser({
        id: user._id,
        data: {
          ...user,
          scope: {
            ...user.scope,
            departmentReport: user.scope.departmentReport.filter(
              departmentId => !departments?.find(d => d._id === departmentId)
            )
          }
        }
      })
    })

  const handleSave = async () => {
    const units = { children: [...orgStructure.units] }

    const data = {
      tree: {
        layers: orgStructure.layers,
        units: units.children
      },
      units: [selectedUnits?.[selectedUnits.length - 1]?._id]
    }
    await mutateOrgStructure(data)
    /**
     * 1. орг юнит не имеет детей, имеет рестораны и имеет менеджеров
     *    на бэке - убираем у ресторанов привязку к орг юниту,
     *    на фронте - у менеджера очищается скоуп ресторанов
     *
     * 2. орг юинт не имеет детей, не имеет ресторанов, имеет мененджеров
     *    на бэке - после удаления орг юнита пропадает и привязка менеджеров к этому юниту
     *
     * 3. орг юнит имеет детей, имеет свои рестораны и вложенные рестораны, имеет своего менеджера, дети имеют своих менеджеров, нет безымянных на его слое
     *    на бэке - орг юнит становится безымянным, привязки ресторанов остаются, привязки менеджеров остаются.
     *
     * 4. орг юнит имеет детей, имеет свои рестораны и вложенные рестораны, имеет своего менеджера, дети имеют своих менеджеров, есть безымянные на его уровне
     *    на бэке - джойним детей удаляемого орг юнита в безымянный орг юнит (с сохранением их менеджеров);
     *    на фронте - менеджеру удаляемого орг юнита записываются в скоуп все деапартаменты безымянного орг юнита + детей
     *    на фронте - менеджеру безымянного орг юнита записываются в скоуп все деапартаменты удаляемого орг юнита + детей
     *
     * 5. При  удалении безымянного орг юнита, удаляется все
     *    на бэке - сам орг юнит, его дети, привязка ресторанов
     *    на фронте - привязка менеджеров
     */
    if (hasChildren) {
      if (deletedUnit) {
        if (selectedUnits?.[selectedUnits.length - 1]?.deleted) {
          /**
           * 5 удаляется привязка менеджеров
           */
          await removeSelectedUnitDepartmentsFromUserScope()
        } else {
          /**
           * 4 менеджеру удаляемого орг юнита записываются в скоуп все деапартаменты безымянного орг юнита + детей
           */
          await joinDepartmentsIntoUserScope(
            selectedUnits?.[selectedUnits.length - 1],
            deletedUnitDepartmentsData?.data
          )
          /**
           * 4 менеджеру безымянного орг юнита записываются в скоуп все деапартаменты удаляемого орг юнита + детей
           */
          await joinDepartmentsIntoUserScope(deletedUnit, departments)
        }
      }
    } else {
      if (departments.length) {
        /**
         * 1 у менеджера очищается скоуп ресторанов
         */
        await removeSelectedUnitDepartmentsFromUserScope()
      }
    }
  }

  const withChildren = deletedUnit ? (
    // заменить у всех рестиков выбранный _id на _id соседа у которого deleted=true
    <Text>
      {`Вложенная структура у "
      ${selectedUnits?.[selectedUnits.length - 1]?.name || noOrgStructureName}" ${
        selectedUnits?.[selectedUnits.length - 1]?.name
          ? `будет перенесена в "${noOrgStructureName}"`
          : 'будет удалена безвозвратно'
      }.`}
    </Text>
  ) : (
    // установить выбраному орг юниту deleted=true и name=''
    <Text>
      Название &quot;{selectedUnits?.[selectedUnits.length - 1]?.name || noOrgStructureName}&quot;
      будет изменено на &quot;{noOrgStructureName}&quot;.
    </Text>
  )

  const withoutChildren = departments.length ? (
    // удалить у всех рестиков выбранный _id
    <>
      <Text>
        Следующие орг единицы будут прикреплены к &quot;
        {selectedUnits?.[selectedUnits.length - penultimateIndex]?.name || noOrgStructureName}
        &quot;:
      </Text>
      <Row className="orgUnit-departments">
        <Col>
          {departments.map(department => (
            <Row key={department._id}>
              {department.name} - {department.address}
            </Row>
          ))}
        </Col>
      </Row>
    </>
  ) : (
    // удалить выбранный _id
    <Text>
      Вложенные структуры не найдены. Вы уверены что хотите удалить &quot;
      {selectedUnits?.[selectedUnits.length - 1]?.name || noOrgStructureName}&quot;
    </Text>
  )

  if (!selectedUnits?.length) return null
  return (
    <div className="org-layer-action">
      <Button
        className="header-button"
        type="link"
        size="small"
        onClick={() => setIsModalVisible(true)}
      >
        <img src={orgDeleteUnitImg} alt="button remove unit" />
        <Text>Удалить</Text>
      </Button>
      <Modal
        title={
          <div className="modal-title">
            <FolderFilled />
            Удалить {selectedUnits?.[selectedUnits.length - 1]?.name || noOrgStructureName}
          </div>
        }
        open={isModalVisible}
        onOk={handleSave}
        onCancel={handleClose}
        className=""
        okText="Удалить"
        okButtonProps={{ loading: isLoadingOrgStructure || isLoadingUpdateUser }}
        cancelButtonProps={{ disabled: isLoadingOrgStructure || isLoadingUpdateUser }}
        cancelText="Отмена"
      >
        {hasChildren ? withChildren : withoutChildren}
      </Modal>
    </div>
  )
}
export default DeleteUnit
